import { set, get } from "idb-keyval";
import configData from './config.json';

export function getPushId(){
    return configData.PUSH;
}

export function getVapidKey(){
    if(configData.BUILD === "PRODUCTION"){
        return configData.FIREBASE.LIVE.VAPIDKEY;
    }else{
        return configData.FIREBASE.DEV.VAPIDKEY;
    }
}

export function getPermissionValue(){
    var returnVal = null;
    get('pushPermission').then(val => returnVal = val);
    return returnVal;
}

export async function setPermissionValue(value){
    await set('pushPermission',value);
}

export async function sendTokenToServer(token){
    let serverUrl;
    if(configData.BUILD === "production"){
        serverUrl = configData.SERVER_URL;
    }else{
        serverUrl = configData.SERVER_URL_DEV;
    }
    return fetch(`${serverUrl}/devreg.php?platform=pwa&notid=${token}`)
    .then(function(response) {
        return response.json();
    }).then(function(data) {
        return data;
    });
}