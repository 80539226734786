import firebase from 'firebase/app';
import '@firebase/messaging';
import configData from '../utils/config.json';

let config;

if(configData.BUILD === "PRODUCTION"){
    config = {
        apiKey: configData.FIREBASE.LIVE.APIKEY,
        authDomain: configData.FIREBASE.LIVE.AUTHDOMAIN,
        databaseURL: configData.FIREBASE.LIVE.DATABASEURL,
        projectId: configData.FIREBASE.LIVE.PROJECTID,
        storageBucket: configData.FIREBASE.LIVE.STORAGEBUCKET,
        messagingSenderId: configData.FIREBASE.LIVE.SENDERID,
        appId: configData.FIREBASE.LIVE.APPID
    };
}else{
    config = {
        apiKey: configData.FIREBASE.DEV.APIKEY,
        authDomain: configData.FIREBASE.DEV.AUTHDOMAIN,
        databaseURL: configData.FIREBASE.DEV.DATABASEURL,
        projectId: configData.FIREBASE.DEV.PROJECTID,
        storageBucket: configData.FIREBASE.DEV.STORAGEBUCKET,
        messagingSenderId: configData.FIREBASE.DEV.SENDERID,
        appId: configData.FIREBASE.DEV.APPID
    };
}
firebase.initializeApp(config);

let messaging;

if(firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
}

// register onMessage to service worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', async () => {
        const registration = await navigator.serviceWorker.ready;
        /*messaging.useServiceWorker(registration);*/ 
        messaging.onMessage((payload) => {
            const data = JSON.parse(payload.data.notification);
            const title = data.title;
            const options = {
                body: data.body,
                icon: data.icon
            };
            registration.showNotification(title, options);           
        });
    });
}

export {
    messaging
};