import React from 'react';
import './App.css';
import WebViewVIO from './components/webViewVIO';

function App() {
  return (
    <div className="App">
      <WebViewVIO />
    </div>
  );
}

export default App;
