import React from 'react';
import ReactDOM from 'react-dom';

class Navigation extends React.Component{
    
    constructor(props){
        super(props);
        this.navigate = this.navigate.bind(this);
        this.openSubMenu = this.openSubMenu.bind(this);
    }
    
    componentDidMount(){
        
    }

    navigate(newUrl){
        //console.log("navigate to "+newUrl);
        const node = ReactDOM.findDOMNode(this);
        if(node instanceof HTMLElement){
            const child = node.querySelectorAll('.viosubmenubutton');
            child.forEach(element => {
                element.classList.remove('open');
                this.props.menuFuntion(false);
            });
        }
        this.props.navFunction(newUrl);
    }

    openSubMenu(item){
        //console.log("openSubMenu "+item);
        var open = false;
        const node = ReactDOM.findDOMNode(this);
        if(node instanceof HTMLElement){
            const child = node.querySelectorAll('.viosubmenubutton');
            child.forEach(element => {
                element.classList.remove('open');
                if(element.classList.contains(item)){
                    if(this.props.lastOpen !== item){
                        element.classList.add('open');
                        open = true;
                        this.props.lastOpen = item;
                    }else{
                        this.props.lastOpen = '';
                        open = false;
                    }
                }
            });
        }
        this.props.menuFuntion(open);
    }

    render() {
        return (
            <div className="navContainer">
                <div className="nav">
                    <div className="viosubmenubutton vsm_icon1 vsm_first" onClick={ () => this.navigate('/')}>Startseite</div>
                    <div className="viosubmenubutton vsm_icon2 vsm_betwn" onClick={ () => this.openSubMenu("vsm_icon2")}>
                        <div className="viosubmenusub">
                            <span className="viosubmenuitem" onClick={ () => this.navigate('/buergerservice/veranstaltungskalender/')}>Veranstaltungen</span>
                            <span className="viosubmenuitem" onClick={ () => this.navigate('/category/s3-aktuelles/')}>Nachrichten</span>
                            <span className="viosubmenuitem" onClick={ () => this.navigate('/category/bekanntmachung/')}>Amtl. Bekanntmachungen</span>
                        </div>
                        Aktuelles
                        <div className="viosubmenuarrow"></div>
                    </div>
                    <div className="viosubmenubutton vsm_icon3 vsm_betwn" onClick={ () => this.openSubMenu("vsm_icon3")}>
                        <div className="viosubmenusub">
                            <span className="viosubmenuitem" onClick={ () => this.navigate('/buergerservice/was-erledige-ich-wo/')}>Was erledige ich wo</span>
                            <span className="viosubmenuitem" onClick={ () => this.navigate('/buergerservice/buergerservice-virtuellesrathaus-verwaltung/buergerservice-virtuellesrathaus-onlineformulare/')}>Onlineantr&auml;ge</span>
                            <span className="viosubmenuitem" onClick={ () => this.navigate('/muelltonnenwecker/')}>M&uuml;lltonnenwecker</span>
                            <span className="viosubmenuitem" onClick={ () => this.navigate('/buergerservice/buergerservice-interaktiverortsplan/')}>Ortsplan</span>
                            <span className="viosubmenuitem" onClick={ () => this.navigate('/buergerservice/buergerservice-aerzte/')}>&Auml;rzte</span>
                            <span className="viosubmenuitem" onClick={ () => this.navigate('/buergerservice/buergerservice-apotheken/')}>Apotheken</span>
                            <span className="viosubmenuitem" onClick={ () => this.navigate('http://marktbuecherei.de')}>Marktb&uuml;cherei</span>
                        </div>
                        B&uuml;rgerservice
                        <div className="viosubmenuarrow"></div>
                    </div>
                    <div className="viosubmenubutton vsm_icon4 vsm_lastn" onClick={ () => this.openSubMenu("vsm_icon4")}>
                        <div className="viosubmenusub">
                            <span className="viosubmenuitem" onClick={ () => this.navigate('/freizeit/freizeit-freibad/')}>Freibad</span>
                            <span className="viosubmenuitem" onClick={ () => this.navigate('/freizeit/freizeit-gastronomie/')}>Gastronomie</span>
                            <span className="viosubmenuitem" onClick={ () => this.navigate('http://www.ferienland-dingolfing-landau.de/')}>Landkreis-Tourismus-Homepage</span>
                            <span className="viosubmenuitem" onClick={ () => this.navigate('https://www.marktbuecherei.de/')}>Marktb&uuml;cherei</span>
                            <span className="viosubmenuitem" onClick={ () => this.navigate('/freizeit/freizeit-radwanderwege/')}>Radwanderwege</span>
                            <span className="viosubmenuitem" onClick={ () => this.navigate('/freizeit/freizeit-sportmoeglichkeiten/')}>Sportm&ouml;glichkeiten</span>
                            <span className="viosubmenuitem" onClick={ () => this.navigate('/buergerservice/veranstaltungskalender/')}>Veranstaltungskalender</span>
                            <span className="viosubmenuitem" onClick={ () => this.navigate('/freizeit/freizeit-vereine/')}>Vereine</span>
                            <span className="viosubmenuitem" onClick={ () => this.navigate('/freizeit/vr-walderlebnispfad/')}>VR-Walderlebnispfad</span>
                        </div>
                        Freizeit
                        <div className="viosubmenuarrow"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Navigation