import React from 'react';
import ReactDOM from 'react-dom';
import Navigation from './navigation';
import Notification from './notification';
import { getPushId } from '../utils/utils';
import configData from '../utils/config.json';

class WebViewVIO extends React.Component{
    
    constructor(props){
        super(props);
        this.state = {
            url: configData.SERVER_URL,
            baseUrl: configData.SERVER_URL
        };
        this.changeView = this.changeView.bind(this);
        this.disableClick = this.disableClick.bind(this);
    }
    
    componentDidMount(){
        
    }

    componentDidUpdate(){
        
    }

    changeView(newUrl){
        var tmpUrl = '';
        if(newUrl.indexOf('http') !== -1){
            tmpUrl = newUrl;
        }else{
            if(newUrl === "/"){
                tmpUrl = this.state.baseUrl;
            }else if(newUrl === "/muelltonnenwecker/"){
                var params = '?platform=pwa';
                if(localStorage.getItem(getPushId()) !== null){
                    params = params + '&notid=' + localStorage.getItem(getPushId());
                }
                tmpUrl = this.state.baseUrl+''+newUrl+''+params;
            }else{
                tmpUrl = this.state.baseUrl+''+newUrl;
            }
        }
        if(tmpUrl !== ''){
            this.setState({url: tmpUrl});
        }
        console.log("changeView: "+tmpUrl);
    }

    disableClick(open){
        const node = ReactDOM.findDOMNode(this);
        if(node instanceof HTMLElement){
            const child = node.querySelector('.iframeContainer');
            if(open){
                child.classList.add('disabled');
            }else{
                child.classList.remove('disabled');
            }
        }
    }

    render() {
        return (
            <div>
                <div className="iframeContainer">
                    <iframe 
                        title="webview"
                        src={this.state.url} 
                        frameBorder={'0'}
                        seamless
                    />
                </div>
                <Navigation navFunction={this.changeView} menuFuntion={this.disableClick} lastOpen=''/>
                <Notification />
            </div>
        )
    }
}

export default WebViewVIO