import React, {  Component } from 'react';
import { messaging } from '../utils/firebase';
import { getPushId, getVapidKey, sendTokenToServer } from '../utils/utils';

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.notificationPermission = this.notificationPermission.bind(this);
    }

    renderSubscriptionOptions() {
        if(!('serviceWorker' in navigator) && !('PushManager' in window)) {
            return(
                <div className="unsupported">
                    Notification feature is supported only in:<br/>
                    Chrome Desktop and Mobile (version 50+)<br/>
                    Firefox Desktop and Mobile (version 44+)<br/>
                    Opera on Mobile (version 37+)
                </div>
            );
        } else {
            let permission = this.notificationPermission();
            return (
                <div style={{display: "none"}}><span>Permission: {permission.permission}, Token: {permission.token}</span></div>
            );
        }
    }

    /**
     * Check if user has already given permission for sending notifications
     * If not, request permission from user, generate instance token and send it to server
     */
    async notificationPermission() {
        let returnVal = {permission: false,token: null};
        try {
            /* request permission if not granted */
            if (Notification.permission !== 'granted') {
                await messaging.requestPermission();
            }
            /* get instance token if not available */
            if (localStorage.getItem(getPushId()) !== null) {
                returnVal.permission = true; 
                returnVal.token = localStorage.getItem(getPushId());
                console.log("old token: "+returnVal.token);
            } else {
                const token = await messaging.getToken({serviceWorkerRegistration: await navigator.serviceWorker.ready,vapidKey: getVapidKey()});
                console.log("new token: "+token);
                localStorage.setItem(getPushId(),token);
                returnVal.permission = true;
                returnVal.token = token;
                // send token to server
                await sendTokenToServer(token);
            }
        } catch (err) {
            console.log(err);
            if (err.hasOwnProperty('code') && err.code === 'messaging/permission-default') console.log('You need to allow the site to send notifications');
            else if (err.hasOwnProperty('code') && err.code === 'messaging/permission-blocked') console.log('Currently, the site is blocked from sending notifications. Please unblock the same in your browser settings');
            else console.log('Unable to subscribe you to notifications');
        } finally {
            return returnVal;
        }
    }

    render() {
        return (
            <div>
                {this.renderSubscriptionOptions()}
            </div>   
        );
    }
}

export default Notifications;